import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'layouts';
import '../styles/prism';
import Helmet from 'react-helmet';
import GridContainer from '../components/Grid/GridContainer.jsx';
import GridItem from '../components/Grid/GridItem.jsx';
import Constants from '../../static/Constants';
import { useTranslation } from 'react-i18next';
import getHeaderLInks from '../shared/headerLinks';
// import getStaticImageURL from '../shared/getStaticImageURL';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ProjectsSearch from '../components/ProjectsSearch';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import Button from '@material-ui/core/Button';
import { filter, find, isEmpty, orderBy, truncate } from 'lodash';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ThreeDots } from 'react-loader-spinner';
import { NEWS_CONTENT } from './newsContent';
import Table from '@material-ui/core/Table';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TablePaginationActions from '../components/News/TablePaginationActions';
import moment from 'moment';
import SocialShare from '../components/SocialShare/SocialShare';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px',
    cursor: 'pointer',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0',
    paddingBottom: '16px',
  },
  cover: {
    width: 100,
    minWidth: 100,
  },
  newsSelected: {
    display: 'flex',
    margin: '10px',
    borderRight: '5px solid #2B3A8C',
    cursor: 'pointer',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  },
  searchResult: {
    minHeight: '50px',
    maxHeight: '425px',
    overflowY: 'auto',
  },
  searchResultBlockNotFound: {
    textAlign: 'center',
    paddingTop: '15px',
    fontSize: '14px',
  },
  comingSoonBlock: {
    height: '300px',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
  },
  comingSoonTextBlock: {
    border: '8px solid #fff',
    padding: '30px 80px',
    boxShadow:
      'rgba(0, 0, 0, 0.15) 0px 2px 8px, rgba(0, 0, 0, 0.15) 0px 2px 8px inset',
    borderRadius: '15px',
    textShadow: '0px 0px 45px #00aec8',
    fontWeight: 600,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
  },
  newsLinkComponent: {
    display: 'flex',
  },
  newsLinkInput: {
    height: '55px',
  },
  tableHeader: {
    padding: '10px 30px',
    marginBottom: '25px !important',
    borderBottom: '1px solid grey',
    minHeight: '50px',
    marginLeft: '15px',
    marginRight: '15px',
    color: 'grey',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '1.8rem',
  },
}));

const News = ({ ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pageContext, location } = props;
  const data = pageContext.pageData ? pageContext.pageData : null;
  if (!data) {
    return;
  }
  const programLink = pageContext.programUrl;
  const headerLinks = getHeaderLInks('', programLink, data.noLinks);
  const { pageHeader, headerLogo, headerLogoScroll } = (data || {}).node || {};
  const logos = pageContext.logos || [];
  const footerLogos = pageContext.footerLogos || [];

  const [selectedNews, setSelectedNews] = useState(0);
  const [listMode, setListMode] = useState(true);
  const [searchString, setSearchString] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [viewNewsLoader, setViewNewsLoader] = useState(false);
  const [isReloadRequired, setIsReloadRequired] = useState(false);
  // const [open, setOpen] = useState(false);
  const [NewsLink, setNewsLink] = useState('');
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [openShareDialog, setOpenShare] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const helmetLink = `${Constants.appUrl}/news`;
  // const baseURL = Constants.apiUrl;
  // const isComingSoon = baseURL == 'https://dipsapi.unido.org' ? true : false;
  const isComingSoon = false;

  let newsList = orderBy(NEWS_CONTENT, ['timestamp'], ['desc']);

  const onNewsSearch = () => {
    setIsSearched(true);
    setSearchLoader(true);
    var search = searchString;
    if (isEmpty(searchString)) {
      setSearchResults([]);
      setIsSearched(false);
    } else {
      var results = filter(newsList, function(item) {
        return item.title.toLowerCase().includes(search.toLowerCase());
      });
      setSearchResults([...results]);
    }
    setTimeout(() => {
      setSearchLoader(false);
    }, 1000);
  };

  const onNewsSelection = (newsId, listmode) => {
    setSelectedNews(newsId);
    setListMode(listmode);
    setViewNewsLoader(true);
    setTimeout(() => {
      setViewNewsLoader(false);
    }, 1000);
  };

  const getNewsTitle = (title, length = 50) => {
    return truncate(title, {
      length: length,
    });
  };

  useEffect(() => {
    // if (isReloadRequired) {
    //   if (selectedNews == -1) {
    //     navigate(location.origin + location.pathname);
    //   } else if (selectedNews != 0) {
    //     navigate(location.origin + location.pathname + `?id=${selectedNews}`);
    //   }
    // }
    if (selectedNews == 0) {
      // console.log('initial state');
    } else {
      if (selectedNews == -1) {
        history.pushState({}, 'news', location.origin + location.pathname);
      } else if (selectedNews != 0) {
        history.pushState(
          {},
          selectedNews,
          location.origin + location.pathname + `?id=${selectedNews}`
        );
      }
    }
  }, [selectedNews]);

  let wn =
    typeof window !== 'undefined'
      ? (window.onpopstate = event => {
          if (event.target.location.search == '') {
            event.target.location.reload();
          }
        })
      : null;

  useEffect(() => {
    if (location.search == '') {
      onNewsSelection(0, true);
    } else {
      let newsID = location.search.split('=');
      onNewsSelection(newsID[1], false);
    }
  }, [location]);

  // const handleClose = () => {
  //   setOpen(false);
  //   setIsLinkCopied(false);
  //   setNewsLink('');
  // };

  const getArticleUrl = newsId => {
    setOpenShare(true);
    setNewsLink(location.origin + location.pathname + `?id=${newsId}`);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLinkCopied(false);
    }, 3000);
  }, [isLinkCopied]);

  const getNewsTimestamp = unixTimestamp => {
    return unixTimestamp
      ? moment(unixTimestamp * 1000).format('DD-MMM-YYYY hh:mm A')
      : '';
  };

  return (
    <Layout
      programLink={programLink}
      title={t('news')}
      content={[]}
      headerLinks={headerLinks}
      logos={logos}
      footerLogos={footerLogos}
      pageHeader={pageHeader}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      imageName="diss.jpg"
      visitedLinks={[{ url: '/', key: 'home', name: 'Home' }]}
      currentPage={{ key: 'news' }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>News</title>
        <link rel="canonical" href={helmetLink} />
      </Helmet>
      <SocialShare
        openShareDialog={openShareDialog}
        setOpenShare={setOpenShare}
        link={NewsLink}
        titleLangKey="share_article"
        mailSubject="Invest In ACP News"
      />
      <div className="container" style={{ padding: '20px 0px' }}>
        {isComingSoon ? (
          <div className={classes.comingSoonBlock}>
            <div className={classes.comingSoonTextBlock}>
              {t('coming_soon')}
            </div>
          </div>
        ) : (
          <>
            <GridContainer style={{ marginTop: '15px' }}>
              <GridItem
                md={4}
                sm={12}
                xs={12}
                style={{ paddingTop: '10px', paddingBottom: '15px' }}
              >
                <ProjectsSearch
                  placeholder={t('search_news')}
                  onProjectsSearch={onNewsSearch}
                  searchString={searchString}
                  setSearchString={setSearchString}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#eee',
                    padding: '15px',
                    borderRadius: '5px',
                  }}
                >
                  {searchLoader ? (
                    <div className="three-dots-loader">
                      <ThreeDots
                        color="#5990ff"
                        height={50}
                        width={50}
                        timeout={10000}
                      />
                    </div>
                  ) : (
                    <div className={classes.searchResult}>
                      {isSearched ? (
                        isEmpty(searchResults) ? (
                          <div className={classes.searchResultBlockNotFound}>
                            {t('no_news_found')}
                          </div>
                        ) : (
                          ''
                        )
                      ) : (
                        <div className={classes.searchResultBlockNotFound}>
                          {t('search_wel_msg')}
                        </div>
                      )}
                      {searchResults.map((news, index) => {
                        if (index <= 8) {
                          return (
                            <Card
                              className={
                                selectedNews == news.id
                                  ? classes.newsSelected
                                  : classes.root
                              }
                              key={index}
                              onClick={() => {
                                onNewsSelection(news.id, false);
                                setIsReloadRequired(true);
                              }}
                              role="presentation"
                            >
                              <CardMedia
                                className={classes.cover}
                                // image={getStaticImageURL('diss.jpg')}
                                image={news.imageUrl}
                                title="news image"
                              />
                              <div className={classes.details}>
                                <CardContent className={classes.content}>
                                  <span
                                    style={{
                                      display: 'flex',
                                      fontSize: '14px',
                                      lineHeight: '15px',
                                      fontWeight: '600',
                                    }}
                                    title={news.title}
                                    dangerouslySetInnerHTML={{
                                      __html: getNewsTitle(
                                        news.title,
                                        selectedNews == news.id ? 35 : 65
                                      ),
                                    }}
                                  >
                                    {/*getNewsTitle(
                                      news.title,
                                      selectedNews == news.id ? 35 : 65
                                    )*/}
                                  </span>
                                  <span
                                    style={{
                                      display: 'flex',
                                      fontSize: '10px',
                                      marginTop: '10px',
                                    }}
                                  >
                                    {news.author}
                                  </span>
                                  <span
                                    style={{
                                      display: 'flex',
                                      fontSize: '10px',
                                    }}
                                  >
                                    {getNewsTimestamp(news.timestamp)}
                                  </span>
                                </CardContent>
                              </div>
                            </Card>
                          );
                        }
                      })}
                    </div>
                  )}
                </div>
              </GridItem>
              {listMode ? (
                <GridItem md={8} sm={12} xs={12}>
                  <div style={{ marginBottom: '15px' }}>
                    <span
                      style={{
                        marginLeft: '25px',
                        fontSize: '32px',
                        fontWeight: '500',
                        marginBottom: '20px',
                      }}
                    >
                      {t('recent_articles')}
                    </span>
                  </div>
                  <div className={classes.tableHeader}>
                    {t('Showing')}&nbsp;
                    {
                      newsList.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      ).length
                    }
                    &nbsp;{t('out of')} {newsList.length} {t('articles')}
                  </div>
                  <GridContainer>
                    {newsList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((news, index) => {
                        // if (index <= 5) {
                        return (
                          <GridItem xs={12} md={6} key={index}>
                            <Card
                              sx={{ maxWidth: 345 }}
                              className={classes.root}
                            >
                              <CardActionArea>
                                <CardMedia
                                  component="img"
                                  height="180"
                                  // image={getStaticImageURL('diss.jpg')}
                                  image={news.imageUrl}
                                  alt="news image"
                                  style={{ marginBottom: '0px' }}
                                  onClick={() => {
                                    onNewsSelection(news.id, false);
                                    setIsReloadRequired(true);
                                  }}
                                />
                                <CardContent
                                  onClick={() => {
                                    onNewsSelection(news.id, false);
                                    setIsReloadRequired(true);
                                  }}
                                  className={classes.content}
                                  style={{
                                    height: '115px',
                                    padding: '25px 15px 0px 15px',
                                  }}
                                >
                                  <span
                                    style={{
                                      display: 'flex',
                                      fontSize: '12px',
                                    }}
                                  >
                                    {getNewsTimestamp(news.timestamp)}
                                  </span>
                                  <span
                                    style={{
                                      display: 'flex',
                                      fontSize: '18px',
                                      lineHeight: '20px',
                                      fontWeight: '600',
                                      paddingTop: '5px',
                                    }}
                                    title={news.title}
                                    dangerouslySetInnerHTML={{
                                      __html: getNewsTitle(news.title),
                                    }}
                                  >
                                    {/*getNewsTitle(news.title)*/}
                                  </span>
                                  <span
                                    style={{
                                      display: 'flex',
                                      fontSize: '12px',
                                      paddingTop: '5px',
                                    }}
                                  >
                                    {news.author}
                                  </span>
                                </CardContent>
                                <CardActions disableSpacing>
                                  <IconButton
                                    aria-label="share"
                                    style={{
                                      marginLeft: 'auto',
                                    }}
                                    onClick={() => getArticleUrl(news.id)}
                                    // onClick={() => setOpenShare(true)}
                                  >
                                    <ShareIcon />
                                  </IconButton>
                                </CardActions>
                              </CardActionArea>
                            </Card>
                          </GridItem>
                        );
                        // }
                      })}
                    <GridContainer key="pager">
                      <GridItem xs={12} sm={12} md={12}>
                        <Table>
                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={3}
                                count={newsList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                  inputProps: {
                                    'aria-label': t('articles_per_page'),
                                  },
                                  native: true,
                                }}
                                labelRowsPerPage={t('articles_per_page')}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                              />
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                </GridItem>
              ) : (
                <GridItem md={8} sm={12} xs={12}>
                  {viewNewsLoader ? (
                    <div className="three-dots-loader">
                      <ThreeDots
                        color="#5990ff"
                        height={100}
                        width={100}
                        timeout={10000}
                      />
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'end',
                          marginBottom: '15px',
                        }}
                      >
                        <Button
                          onClick={() => {
                            onNewsSelection(-1, true);
                            setIsReloadRequired(true);
                          }}
                          className="btn-primary"
                          variant="contained"
                          startIcon={<ArrowBackIcon />}
                        >
                          {t('go_back_recent_articles')}
                        </Button>
                      </div>

                      <div
                        style={{ fontSize: '36px', fontWeight: '700' }}
                        dangerouslySetInnerHTML={{
                          __html: find(newsList, { id: selectedNews })?.title,
                        }}
                      >
                        {/*find(newsList, { id: selectedNews })?.title*/}
                        {/* {newsList[selectedNews].title} */}
                      </div>
                      <div
                        style={{
                          fontSize: '14px',
                          display: 'flex',
                          alignItems: 'center',
                          color: '#aaa',
                          marginTop: '10px',
                        }}
                      >
                        {/* <img
                        src={'/vectors/bot.jpg'}
                        alt="author"
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                          marginBottom: '0px',
                          marginRight: '10px',
                        }}
                      ></img> */}
                        {find(newsList, { id: selectedNews })?.author}
                        &nbsp;&nbsp; |&nbsp;&nbsp;
                        {getNewsTimestamp(
                          find(newsList, { id: selectedNews })?.timestamp
                        )}
                      </div>
                      <div
                        style={{
                          fontSize: '16px',
                          marginTop: '40px',
                          textAlign: 'left',
                        }}
                      >
                        <div
                          id="news-description"
                          dangerouslySetInnerHTML={{
                            __html: find(newsList, { id: selectedNews })
                              ?.newsContent,
                          }}
                        ></div>
                      </div>
                    </>
                  )}
                </GridItem>
              )}
            </GridContainer>
          </>
        )}
      </div>
      {/* <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h2 style={{ fontSize: '20px', fontWeight: 600 }}>
                {t('share_article')}
              </h2>
              <div className={classes.newsLinkComponent}>
                <input
                  type="text"
                  disabled
                  value={newsLink}
                  className={classes.newsLinkInput}
                />
                <Button
                  onClick={() => {
                    copyTextToClipboard(newsLink);
                  }}
                  className="btn-primary"
                  variant="contained"
                  disabled={isLinkCopied}
                >
                  {isLinkCopied ? t('copied') + '!' : t('copy')}
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div> */}
    </Layout>
  );
};

export default News;

News.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};
