import React, { useState, useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkIcon from '@material-ui/icons/Link';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { useTranslation } from 'react-i18next';

const SocialShare = ({
  openShareDialog,
  setOpenShare,
  link = '',
  titleLangKey = '',
  mailSubject = '',
}) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const { t } = useTranslation();

  let iconColor = '#498fe0';
  const facebookLink = 'https://www.facebook.com/share.php?u';
  // const EmURL = 'https://mail.google.com/mail/?view=cm&fs=1&su';
  // const portalApiLink = Constants.apiUrl;
  const twitterURL = 'http://twitter.com/intent/tweet?url=URL&text';

  async function copyTextToClipboard(text) {
    setIsLinkCopied(true);
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  useEffect(() => {
    if (isLinkCopied) {
      setTimeout(() => {
        setIsLinkCopied(false);
        setOpenShare(false);
      }, 3000);
    }
  }, [isLinkCopied]);

  return (
    <Dialog
      aria-labelledby="shareDialog"
      onClose={() => setOpenShare(false)}
      open={openShareDialog}
    >
      <DialogTitle id="shareDialog">
        <span className="share-project-title-label">{t(titleLangKey)}</span>
      </DialogTitle>

      <List sx={{ pt: 0 }} style={{ width: '300px' }}>
        <ListItem
          button
          onClick={() => {
            window.open(
              'mailto:?Subject=' +
                mailSubject +
                '&body=' +
                encodeURIComponent(link)
            );
            setOpenShare(false);
          }}
          key={'Email-share'}
        >
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: iconColor }}>
              <MailOutlineIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<span className="share-project-label">Email</span>}
          />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            window.open(facebookLink + '=' + encodeURIComponent(link));
            setOpenShare(false);
          }}
          key={'FB-share'}
        >
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: iconColor }}>
              <FacebookIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<span className="share-project-label">Facebook</span>}
          />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            window.open(twitterURL + '=' + encodeURIComponent(link));
            setOpenShare(false);
          }}
          key={'Twitter-share'}
        >
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: iconColor }}>
              <TwitterIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<span className="share-project-label">Twitter</span>}
          />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            copyTextToClipboard(link);
            setIsLinkCopied(true);
          }}
          key={'Link-share'}
        >
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: iconColor }}>
              <LinkIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <span className="share-project-label">{t('copy_link')}</span>
            }
          />
        </ListItem>
        {isLinkCopied ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              backgroundColor: '#90EE90',
              margin: '10px',
              padding: '10px',
              borderRadius: '5px',
              fontSize: '12px',
            }}
          >
            {t('copy_link_clipboard')}
          </div>
        ) : (
          ''
        )}
      </List>
    </Dialog>
  );
};

export default SocialShare;
